
import React, { useState, useEffect  } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Grid,
  Header,
  Segment,
  Image,
  Message
} from "semantic-ui-react";
import './FrontPage_fall_2023.css'

import {Media} from "../../App";
import {HeaderAnimation} from '../../Components/HeaderAnimation/HeaderAnimation'
import {CardCarousel} from '../../Components/CardCarousel/CardCarousel'
import BannerMobile from '../../static/img/palm_earring_banner_mobile2.jpg';
import { baseServer} from "../../constants";

const promo = {
        "active":false,
        "header":"Black Friday Sale",
        "subheader":"Use code FRIDAY15 at checkout for 15% off your order",
        }

const announcement = {
        "active":false,
        "text":"Holiday Sale Extended",
        }


export function FrontPage (props) {

  useEffect( () => {
    window.scrollTo(0, 0);
  },[]
  );

  const SaleBanner = () =>{
    return(
      <Grid.Row>
      <Segment className="desktop-sale-banner" >
      <div className="sale-banner-text-box">
      <Header className="sale-banner-text">
      {promo.header}
      </Header>
      <Header className="sale-banner-text-sub">
      {promo.subheader}
      </Header>
      </div>
      </Segment>
      </Grid.Row>
    )
  }

  return(
    <div>
    <Media at="mobile">
    <Grid container style={{margin:"1em 0em"}}>
    {promo.active && (
      <SaleBanner/>
    )}
    {announcement.active && (
      <Grid.Row style={{padding:"0em 0em 1em 0em"}}>
      <Grid.Column style={{padding:"0em"}}>
      <Message>
      <Message.Header>Shipping Update</Message.Header>
      <p style={{fontSize:"1em"}}>
      All orders placed between Nov 29 and Dec 2 will be shipped on Dec 4
      </p>
      </Message>
      </Grid.Column>
      </Grid.Row>
    )}
    <Grid.Row style={{padding:"0em 0em 0.5em 0em"}}>
      <Grid.Column style={{padding:"0em"}}>
      <Segment className="mobile-top-jumbotron">
      <div className="header-animation-center">
      <HeaderAnimation/>
      </div>
        </Segment>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{padding:"2em 0em"}}>
    <Grid.Column textAlign="center">
    <p className="featured-font">Featured in</p>

    <Image centered href="https://www.vogue.co.uk/gallery/vogue-retail-jewellery" src={`${baseServer}/media/british-vogue-logo.jpg`} size="medium"/>

    </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{padding:"0.5em 0em"}}>
      <Grid.Column style={{padding:"0"}}>
      <Link to="/products/necklaces">
      <Segment className="category-segment-necklaces">
      <p className="category-font">
      Necklaces
      </p>
      <p className="shop-now">
      Shop Now
      </p>
      </Segment>
      </Link>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns={2} style={{padding:"0.5em 0em"}}>
      <Grid.Column style={{padding:"0em 0.5em 0em 0em"}}>
      <Link to="/products/earrings">
        <Segment className="category-segment-earrings">
        <p className="category-font">
        Earrings
        </p>
        <p className="shop-now">
        Shop Now
        </p>
        </Segment>
      </Link>
      </Grid.Column>
      <Grid.Column style={{padding:"0em 0em 0em 0.5em"}}>
      <Link to="/products/bracelets">
      <Segment className="category-segment-bracelets">
      <p className="category-font">
      Bracelets
      </p>
      <p className="shop-now">
      Shop Now
      </p>
      </Segment>
      </Link>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{padding:"0.5em 0em"}}>
      <Grid.Column style={{padding:"0em 0.5em 0em 0em"}}>
      <Link to="/products/anklets">
      <Segment className="category-segment-anklets">
      <p className="category-font">
      Anklets
      </p>
      <p className="shop-now">
      Shop Now
      </p>
      </Segment>
      </Link>
      </Grid.Column>

    </Grid.Row>
    <Grid.Row style={{padding:"0.5em 0em"}}>
      <Grid.Column style={{padding:"0em"}}>
      <Segment className="mobile-bottom-jumbotron">
      <Header as="h1" >Best</Header>
      <Header as="h1" >Sellers</Header>
      <Link to="/products">
      <Button inverted basic size="large" style={{margin:"2em 0em"}} color="black" >Shop Now</Button>
      </Link>
        </Segment>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row style={{padding:"0.5em 0em"}}>
      <Grid.Column style={{padding:"0em"}}>
      <Link to="/products/stella">
      <Segment className="mobile-stella-jumbotron">

      <div className="stella-logo" >
      <Image  src="https://api.coellalove.com/media/stella_logo2.png" style={{width:"350px"}}/>
      <p className="stella-sub">
      Our mother daughter line
      </p>
      <p className="shop-now">
      Shop Now
      </p>
      </div>
        </Segment>
        </Link>
      </Grid.Column>
    </Grid.Row>
    </Grid>
    </Media>

    <Media greaterThan="mobile">
    <Grid container style={{margin:"1em 0em"}}>
      {promo.active && (
        <SaleBanner/>
      )}
      {announcement.active && (
        <Grid.Row style={{padding:"0em 0em 1em 0em"}}>
        <Grid.Column style={{padding:"0em"}}>
        <Message>
        <Message.Header>Shipping Update</Message.Header>
        <p style={{fontSize:"1em"}}>
        All orders placed between Nov 29 and Dec 2 will be shipped on Dec 4
        </p>
        </Message>
        </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row columns={2} style={{padding:"0em 0em 0.5em 0em"}}>
        <Grid.Column width={8} style={{padding:"0em 0.5em 0em 0em"}}>
          <Segment className="desktop-top-jumbotron-left" >
          <div className="header-animation-center">
          <HeaderAnimation/>
          </div >
          </Segment>
        </Grid.Column>
        <Grid.Column width={8} style={{padding:"0em 0em 0em 0.5em"}}>
          <Segment className="desktop-top-jumbotron-right">
          <div style={{height:"300px"}}>

          </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{padding:"2em 0em"}}>
        <Grid.Column textAlign="center">
        <p className="featured-font">Featured in</p>
        <Image centered href="https://www.vogue.co.uk/gallery/vogue-retail-jewellery" src={`${baseServer}/media/british-vogue-logo.jpg`} size="medium"/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} style={{padding:"0.5em 0em"}}>
        <Grid.Column  style={{padding:"0em 0.5em 0em 0em"}}>
          <Link to="/products/necklaces">
            <Segment className="category-segment-necklaces">
            <p className="category-font">
            Necklaces
            </p>
            <p className="shop-now">
            Shop Now
            </p>
            </Segment>
          </Link>
        </Grid.Column>
        <Grid.Column style={{padding:"0em 0em 0em 0.5em"}}>
          <Link to="/products/earrings">
            <Segment className="category-segment-earrings">
            <p className="category-font">
            Earrings
            </p>
            <p className="shop-now">
            Shop Now
            </p>
            </Segment>
          </Link>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} style={{padding:"0.5em 0em"}}>
        <Grid.Column style={{padding:"0em 0.5em 0em 0em"}}>
          <Link to="/products/bracelets">
          <Segment className="category-segment-bracelets">
          <p className="category-font">
          Bracelets
          </p>
          <p className="shop-now">
          Shop Now
          </p>
          </Segment>
          </Link>
        </Grid.Column>
        <Grid.Column style={{padding:"0em 0em 0em 0.5em"}}>
          <Link to="/products/anklets">
          <Segment className="category-segment-anklets">
          <p className="category-font">
          Anklets
          </p>
          <p className="shop-now">
          Shop Now
          </p>
          </Segment>
          </Link>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} style={{padding:"0.5em 0em"}}>
        <Grid.Column width={10} style={{padding:"0em 0.5em 0em 0em"}}>
          <Segment className="desktop-stella-jumbotron-left">
            <div className="stella-logo-desktop">
            <Image src="https://api.coellalove.com/media/stella_logo2.png" fluid style={{width:"250px"}}/>
            <p className="stella-sub">
            Our mother daughter line
            </p>
            </div>
            <Link to="products/stella">
            <Button inverted basic size="large" style={{margin:"2em 0em"}} color="black" >Shop Now</Button>
            </Link>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6} style={{padding:"0em 0em 0em 0.5em"}}>
          <Segment className="desktop-stella-jumbotron-right">
            <Header as="h1" >Best</Header>
            <Header as="h1" >Sellers</Header>
            <Link to="/products">
            <Button inverted basic size="large" style={{margin:"2em 0em"}} color="black" >Shop Now</Button>
            </Link>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    </Media>
    </div>
  )

}
